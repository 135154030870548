<template>
  <div>
    <table class="tw-min-w-full tw-divide-y tw-divide-neutral-300 tw-table-fixed tw-border-b tw-border-t tw-border-neutral-300">
      <thead :class="headerClass">
        <slot name="thead" :headers="columns">
          <tr>
            <slot name="thead-tr">
              <th
                v-for="col in columns"
                :key="col.key"
                scope="col"
                class="tw-py-3 tw-pr-4 tw-pl-2 tw-text-sm tw-text-neutral-700 tw-uppercase tw-antialiased tw-whitespace-nowrap tw-text-left"
                :class="computeHeaderClass(col)"
                @click="handleSort(col)"
              >
                <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <slot :name="`Head-${col.slot}`" :col="col">
                    {{ col.title || '' }}
                  </slot>

                  <slot v-if="col.sortable" :name="`Head-${col.slot}-Sort`" :col="col">
                    <i
                      v-if="isColumnBeingSorted(col)"
                      :class="{
                        'el-icon-arrow-up': sorting.direction === 'asc',
                        'el-icon-arrow-down': sorting.direction === 'desc',
                      }"
                    />
                  </slot>
                </div>
              </th>
            </slot>
          </tr>
        </slot>
      </thead>
      <tbody class="tw-divide-y tw-divide-neutral-300">
        <slot name="tbody">
          <tr v-if="loading">
            <td colspan="9" class="tw-h-[450px] tw-bg-gray-100/50">
              <div class="tw-flex tw-justify-center tw-items-center">
                Loading...
              </div>
            </td>
          </tr>
          <tr v-else-if="entries.length === 0">
            <td colspan="9" class="tw-h-[450px] tw-bg-gray-100/50">
              <div class="tw-flex tw-justify-center tw-items-center">
                <span class="tw-text-md tw-text-gray-500 tw-font-normal tw-tracking-wide tw-antialiased">
                  There's no information available.
                </span>
              </div>
            </td>
          </tr>
          <tr
            v-for="(row, rowIndex) in entries"
            :key="rowIndex"
            class="{
              'tw-bg-black': row.highlight,
              'tw-text-white': row?.highlight,
              'tw-text-neutral-950': !row?.highlight,
            }"
          >
            <slot name="tbody-tr" :row-data="row" :row-index="rowIndex">
              <td
                v-for="(col, index) in columns"
                :key="index"
                class="tw-py-3 tw-pr-4 tw-pl-2 tw-text-sm tw-text-left tw-whitespace-nowrap"
                :class="computeRowClass(col)"
              >
                <slot
                  :name="`Body-${col.slot}`"
                  :col="col"
                  :row="row"
                  :index="rowIndex"
                >
                  {{ row[col.key] ? row[col.key] : '-' }}
                </slot>
              </td>
            </slot>
          </tr>
        </slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },

    sorting: {
      type: Object,
      required: false,
      default: function () {
        return {
          column: undefined,
          direction: undefined
        }
      }
    },

    columns: {
      type: Array,
      required: true
    },

    records: {
      type: Array,
      required: true
    },

    headerClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      entries: []
    }
  },

  watch: {
    records () {
      this.entries = this.records
    }
  },

  methods: {
    determineNextSortingDirection (col) {
      const sortKey = col.sortKey ? col.sortKey : col.key

      if (this.sorting.column !== sortKey) {
        return 'desc'
      }

      switch (this.sorting.direction) {
        case undefined:
          return 'asc'
        case 'asc':
          return 'desc'
        default:
          return 'asc'
      }
    },

    computeHeaderClass (col) {
      let classes = [col.headerClass ? col.headerClass : '']

      if (col.sortable) {
        classes.push('tw-cursor-pointer')
      }

      return classes.join(' ')
    },

    computeRowClass (col) {
      return [col.rowClass ? col.rowClass : ''].join(' ')
    },

    handleSort (col) {
      if (col.sortable) {
        this.$emit('sort-changed', {
          column: col.sortKey ? col.sortKey : col.key,
          direction: this.determineNextSortingDirection(col)
        })
      }
    },

    isColumnBeingSorted (col) {
      if (col.sortable && this.sorting.column) {
        return this.sorting.column === (col.sortKey ? col.sortKey : col.key)
      }

      return false
    }
  }
}
</script>
