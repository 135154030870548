<template>
  <span
    class="tw-antialiased"
    :class="{
      'tw-border-b-1.5': showBorder,
      'tw-border-solid tw-border-b tw-border-red-500': isNegativeValue && showBorder,
      'tw-border-solid tw-border-b tw-border-green-500': !isNegativeValue && showBorder,
      'tw-text-red-500': isNegativeValue && colorizeText,
      'tw-text-green-500': !isNegativeValue && colorizeText,
    }"
  >
    {{ value }}
  </span>
</template>

<script>
import { isNegative } from '@/utils/number'

export default {
  props: {
    value: { type: [String, Number], required: true },
    colorizeText: { type: Boolean, required: true },
    showBorder: { type: Boolean, default: false },
    type: { type: String, default: 'unknown' }
  },

  computed: {
    isNegativeValue () {
      return isNegative(this.value)
    }
  }
}
</script>
