<template>
  <div class="tw-flex tw-flex-row tw-gap-6">
    <div class="tw-w-2/3">
      <DataTable
        :sorting="sorting"
        :columns="columns"
        :records="entities"
        :loading="loading"
        @sort-changed="handlSortChanged"
      >
        <template #Body-Rank="{ row }">
          {{ row.rank }}
        </template>

        <template #Body-Name="{ row }">
          <span class="tw-font-bold">{{ row.name }}</span>
        </template>

        <template #Body-TrustValue="{ row }">
          <span v-if="!row.latest_score">n/a</span>
          <ColorizeValue
            v-else
            :value="formatNumber(row.latest_score)"
            :colorize-text="false"
            :show-border="true"
          />
        </template>

        <template #Body-NetChange="{ row }">
          <span v-if="!row.score_1day">n/a</span>
          <ColorizeValue
            v-else
            :value="formatNumber(row.score_1day)"
            :colorize-text="false"
            :show-border="true"
          />
        </template>

        <template #Body-7Days="{ row }">
          <span v-if="!row.score_7days">n/a</span>
          <ColorizeValue
            v-else
            :value="formatNumber(row.score_7days)"
            :colorize-text="false"
            :show-border="true"
          />
        </template>

        <template #Body-15Days="{ row }">
          <span v-if="!row.score_15days">n/a</span>
          <ColorizeValue
            v-else
            :value="formatNumber(row.score_15days)"
            :colorize-text="false"
            :show-border="true"
          />
        </template>
      </DataTable>
    </div>

    <div class="tw-w-1/3 tw-flex tw-flex-col tw-gap-4">
      <div class="tw-flex tw-items-center">
        <div class="tw-grow-0 tw-pr-4 tw-text-3xl tw-font-bold tw-tracking-wide">
          Topics
        </div>

        <div class="tw-grow tw-h-1 tw-bg-black" />
      </div>

      <div class="">
        <div
          v-for="topic in topics"
          :key="topic.value"
          class="tw-inline-flex tw-flex-row tw-items-center tw-gap-2 tw-py-1.5 tw-px-3 tw-rounded-3xl tw-mr-2 tw-mb-2 tw-border-[1.5px] tw-border-black tw-text-sm border-red-600"
        >
          {{ topic.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/utils/number.js'
import ColorizeValue from '@/pages/trends/ColorizeValue.vue'
import DataTable from '@/pages/trends/DataTable.vue'

export default {
  components: {
    DataTable,
    ColorizeValue
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },

    entities: {
      type: Array,
      required: true
    },

    topics: {
      type: Array,
      required: true
    },

    sorting: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      columns: [
        {
          key: 'rank',
          slot: 'Rank',
          title: 'Rank',
          sortable: false,
          headerClass: 'tw-w-[10%]'
        },
        {
          key: 'name',
          slot: 'Name',
          title: 'Name',
          sortable: true,
          sortKey: 'name',
          headerClass: 'tw-w-[42%]'
        },
        {
          key: 'latest_score',
          slot: 'TrustValue',
          title: 'Trust Value',
          sortable: true,
          sortKey: 'latest',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_1day',
          slot: 'NetChange',
          title: 'Net Change',
          sortable: true,
          sortKey: 'last-day',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_7days',
          slot: '7Days',
          title: '7 Days',
          sortable: true,
          sortKey: 'last-7-days',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_15days',
          slot: '15Days',
          title: '15 Days',
          sortable: true,
          sortKey: 'last-15-days',
          headerClass: 'tw-w-[12%]'
        }
      ]
    }
  },

  methods: {
    formatNumber: (number) => formatNumber(number),

    handlSortChanged (sorting) {
      this.$emit('sorting:update', {
        column: sorting.column,
        direction: sorting.direction
      })
    }
  }
}
</script>
