export function isNegative (number) {
  let cleanedNumber = number

  if (isNaN(number)) {
    cleanedNumber = cleanedNumber.replace(/[^\d-]/g, '')
  }

  return Math.sign(cleanedNumber) === -1
}

export function formatNumber (number, locale = 'de-DE') {
  return Intl.NumberFormat(locale).format(number)
}
