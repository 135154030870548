var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-row tw-gap-6"},[_c('div',{staticClass:"tw-w-2/3"},[_c('DataTable',{attrs:{"sorting":_vm.sorting,"columns":_vm.columns,"records":_vm.entities,"loading":_vm.loading},on:{"sort-changed":_vm.handlSortChanged},scopedSlots:_vm._u([{key:"Body-Rank",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.rank)+" ")]}},{key:"Body-Name",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(row.name))])]}},{key:"Body-TrustValue",fn:function(ref){
var row = ref.row;
return [(!row.latest_score)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.latest_score),"colorize-text":false,"show-border":true}})]}},{key:"Body-NetChange",fn:function(ref){
var row = ref.row;
return [(!row.score_1day)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_1day),"colorize-text":false,"show-border":true}})]}},{key:"Body-7Days",fn:function(ref){
var row = ref.row;
return [(!row.score_7days)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_7days),"colorize-text":false,"show-border":true}})]}},{key:"Body-15Days",fn:function(ref){
var row = ref.row;
return [(!row.score_15days)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_15days),"colorize-text":false,"show-border":true}})]}}])})],1),_c('div',{staticClass:"tw-w-1/3 tw-flex tw-flex-col tw-gap-4"},[_vm._m(0),_c('div',{},_vm._l((_vm.topics),function(topic){return _c('div',{key:topic.value,staticClass:"tw-inline-flex tw-flex-row tw-items-center tw-gap-2 tw-py-1.5 tw-px-3 tw-rounded-3xl tw-mr-2 tw-mb-2 tw-border-[1.5px] tw-border-black tw-text-sm border-red-600"},[_vm._v(" "+_vm._s(topic.label)+" ")])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-grow-0 tw-pr-4 tw-text-3xl tw-font-bold tw-tracking-wide"},[_vm._v(" Topics ")]),_c('div',{staticClass:"tw-grow tw-h-1 tw-bg-black"})])}]

export { render, staticRenderFns }